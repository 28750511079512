







































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  GraphicResponse,
  HistoryLogResponse,
  HistoryResponse,
} from "@/generated/adminapi";
import JSZip from "jszip";
import { saveAs } from "file-saver";

interface svgStyleInterface {
  height: string;
}

@Component
export default class ShowImage extends Vue {
  @Prop({ required: true, default: {} }) historyLog!: HistoryLogResponse;
  @Prop() testerInfo!: HistoryResponse;

  svgDatas = [];
  serverData = [];
  loadingState = false;

  carouselHeight = "";
  svgHeight = "";

  get svgStyle(): svgStyleInterface {
    return {
      height: this.svgHeight,
    };
  }

  set svgStyle(svgStyleObj: svgStyleInterface) {
    this.svgHeight = svgStyleObj.height;
  }

  updated(): void {
    this.getViewboxAndSetWidth();
  }

  async showImage(UUID: string): Promise<void> {
    try {
      this.loadingState = true;
      if (!this.historyLog.game) return;

      const data = await this.$_apiHelper().v1AdminGraphicEyeTracking({
        historyLogUUID: UUID,
        serverUrl: "",
      });

      this.handleFetchData(data);
    } catch (e) {
      this.$_errorMessage(e);
    } finally {
      this.loadingState = false;
    }
  }

  handleFetchData(data: GraphicResponse | string): void {
    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      return this.$_errorMessage(data);
    }

    if (!data.svgList) {
      this.$_haii.analytics.errorEvent(this.$_haiiText("noImage"));
      return this.$_errorMessage(this.$_haiiText("noImage"));
    }
    this.$_haii.analytics.readEvent(this.$_haiiName() + "_handleFetchData");

    this.serverData = data.svgList.map((value) => window.atob(value));

    const svgList = data.svgList.map((value) => window.atob(value));

    if (
      this.historyLog.game.itype == 8400 ||
      this.historyLog.game.itype == 8700
    ) {
      for (let i = 0; i < svgList.length; i += 3) {
        this.svgDatas.push(svgList.slice(i, i + 3));
      }
    } else {
      for (let i = 0; i < svgList.length; i += 5) {
        this.svgDatas.push(svgList.slice(i, i + 5));
      }
    }
  }

  imagesDownload(): void {
    if (this.svgDatas) {
      this.$_haii.analytics.readEvent(this.$_haiiName() + "_imagesDownload");
      const zip = new JSZip();
      const userName = this.testerInfo.user?.displayName;
      const gameName = this.historyLog.game?.displayName;
      this.serverData.forEach((data, index) => {
        zip.file(`${userName}_${gameName}_${index}.svg`, data);
      });

      zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, `${userName}_${gameName}.zip`);
      });
    }
  }

  getViewboxAndSetWidth(): void {
    if (this.svgDatas && this.svgDatas[0]) {
      const viewbox = this.svgDatas[0][0].match(
        "0 0 \\d{0,}.\\d{0,} \\d{0,}.\\d{0,}"
      );

      if (viewbox) {
        const wh = viewbox[0].split(" ");
        const width = parseInt(wh[2]);
        const height = parseInt(wh[3]);
        const container = this.$refs.container as HTMLElement;
        const containerWidth = container.clientWidth;
        this.svgStyle = { height: (containerWidth * height) / width + "px" };
        this.carouselHeight = (containerWidth * height) / width + 50 + "px";
      }
    }
  }
}
