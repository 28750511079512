
































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryResponse } from "@/generated/adminapi";

@Component
export default class CistResult extends Vue {
  @Prop() testerInfo!: HistoryResponse;
}
