
















































import { Component, Prop, Vue } from "vue-property-decorator";
import VueUploadComponent from "vue-upload-component";
import { UploadFileInterface } from "@/types/objectType";

@Component({
  components: {
    FileUpload: VueUploadComponent,
  },
})
export default class FileUploader extends Vue {
  @Prop({ default: "" }) files: UploadFileInterface[];
  flag = false;

  get dropActive(): string {
    return this.flag ? "grey lighten-2" : "";
  }

  updated(): void {
    const upload = this.$refs.upload as InstanceType<typeof VueUploadComponent>;
    if (upload) {
      this.flag = upload.dropActive;
    }
  }

  get cFiles(): UploadFileInterface[] {
    return this.files;
  }

  set cFiles(val: UploadFileInterface[]) {
    return;
  }

  initCFiles(file?: UploadFileInterface): void {
    if (file) {
      this.$emit("inputFile", [file]);
    } else {
      this.$emit("inputFile", []);
    }
  }

  inputFile(file: UploadFileInterface): void {
    this.initCFiles(file);
  }

  inputFilter(
    newFile: UploadFileInterface,
    oldFile: UploadFileInterface,
    prevent
  ): void {
    if (newFile && !oldFile) {
      if (!/\.(xls|csv|xlsx)$/i.test(newFile.name)) {
        this.$_errorMessage("엑셀 확장자만 업로드 해주세요");
        return prevent();
      }
    }

    // Create a blob field
    newFile.blob = "";
    let URL = window.URL || window.webkitURL;
    if (URL && URL.createObjectURL) {
      newFile.blob = URL.createObjectURL(newFile.file);
    }
  }
}
