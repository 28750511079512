








































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryResponse } from "@/generated/adminapi";

@Component
export default class BasicInfo extends Vue {
  @Prop({ type: Object, default: null, required: true })
  readonly testerInfo!: HistoryResponse;

  get isKo(): boolean {
    return this.$store.getters.setting.locale == "ko";
  }
}
