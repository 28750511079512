






































import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryLogResponse } from "@/generated/adminapi";

export type HistoryLogResponseRE = HistoryLogResponse & {
  detail: [
    {
      answer: string;
      question: string;
      userAnswer: string;
      description: string;
      answerResult: string;
      questionNumber: string;
      questionEndTime: string;
      questionStartTime: string;
      questionResponseTime: number;
      extra?: { soundFile?: string };
    }
  ];
};

export interface gameResultSummaryInterface {
  totalNumOfQuestion: number;
  correctAnswerNum: number;
  playTime: string;
  averageResponseTime: string;
}

@Component
export default class ResultSummary extends Vue {
  @Prop({ required: true, default: {} }) historyLog!: HistoryLogResponseRE;

  get headers() {
    const gameNumList = [{}];
    if (this.historyLog.game.itype === 9000) {
      this.historyLog.detail
        .filter((value) => !value.extra?.soundFile)
        .forEach((value, index) =>
          gameNumList.push({
            text: index,
            value: `${index}`,
          })
        );
      gameNumList[0] = {
        text: "header",
        align: "start",
        value: "header",
      };

      return gameNumList;
    }

    this.historyLog.detail.forEach((value, index) =>
      gameNumList.push({
        text: index,
        value: `${index}`,
      })
    );
    gameNumList[0] = {
      text: "header",
      align: "start",
      value: "header",
    };

    return gameNumList;
  }

  get tableData() {
    let questions = {};
    let answer = {};
    let responseTime = {};
    if (this.historyLog.game.itype === 9000) {
      this.historyLog.detail
        .filter((value) => !value.extra?.soundFile)
        .forEach((value, index) => {
          questions = {
            ...questions,
            [index]: index,
          };
          answer = {
            ...answer,
            [index]: value.answerResult === "정답" ? "O" : "X",
          };
          responseTime = {
            ...responseTime,
            [index]: this.$_haii.datetime.secondsToHHMMSS(
              value.questionResponseTime
            ),
          };
        });
      return [
        { header: this.$_haiiText("question"), ...questions },
        { header: this.$_haiiText("answer"), ...answer },
        { header: this.$_haiiText("responseTime"), ...responseTime },
      ];
    }

    this.historyLog.detail.forEach((value, index) => {
      questions = {
        ...questions,
        [index]: index,
      };
      answer = {
        ...answer,
        [index]: value.answerResult === "정답" ? "O" : "X",
      };
      responseTime = {
        ...responseTime,
        [index]: this.$_haii.datetime.secondsToHHMMSS(
          value.questionResponseTime
        ),
      };
    });
    return [
      { header: this.$_haiiText("question"), ...questions },
      { header: this.$_haiiText("answer"), ...answer },
      { header: this.$_haiiText("responseTime"), ...responseTime },
    ];
  }

  get tbodyData(): gameResultSummaryInterface[] {
    if (this.historyLog.game.itype === 9000) {
      const totalNumOfQuestion = this.historyLog.detail.filter(
        (value) => !value.extra?.soundFile
      ).length;

      const correctAnswerNum = (): number => {
        let num = 0;
        this.historyLog.detail
          .filter((value) => !value.extra?.soundFile)
          .forEach((value) => {
            if (value.answerResult == "정답") num++;
          });
        return num;
      };

      const playTime = this.$_haii.datetime.timeToHHMMSS(
        this.historyLog.endTM,
        this.historyLog.startTM
      );

      const totalSeconds = () => {
        let totalSeconds = 0;
        this.historyLog.detail
          .filter((value) => !value.extra?.soundFile)
          .forEach((value) => {
            const gap =
              this.$_haii.datetime.timeGapToSeconds(value.questionEndTime) -
              this.$_haii.datetime.timeGapToSeconds(value.questionStartTime);
            totalSeconds += gap;
          });
        return totalSeconds;
      };

      const averageResponseTime = () => {
        let averageTime = "";

        averageTime = this.$_haii.datetime.secondsToHHMMSS(
          totalSeconds() / totalNumOfQuestion
        );
        return averageTime;
      };

      return [
        {
          totalNumOfQuestion,
          correctAnswerNum: correctAnswerNum(),
          playTime,
          averageResponseTime: averageResponseTime(),
        },
      ];
    }

    const totalNumOfQuestion = this.historyLog.detail.length;

    const correctAnswerNum = (): number => {
      let num = 0;
      this.historyLog.detail.forEach((value) => {
        if (value.answerResult == "정답") num++;
      });
      return num;
    };

    const playTime = this.$_haii.datetime.timeToHHMMSS(
      this.historyLog.endTM,
      this.historyLog.startTM
    );

    const totalSeconds = () => {
      let totalSeconds = 0;
      this.historyLog.detail.forEach((value) => {
        const gap =
          this.$_haii.datetime.timeGapToSeconds(value.questionEndTime) -
          this.$_haii.datetime.timeGapToSeconds(value.questionStartTime);
        totalSeconds += gap;
      });
      return totalSeconds;
    };

    const averageResponseTime = () => {
      let averageTime = "";

      averageTime = this.$_haii.datetime.secondsToHHMMSS(
        totalSeconds() / totalNumOfQuestion
      );
      return averageTime;
    };

    return [
      {
        totalNumOfQuestion,
        correctAnswerNum: correctAnswerNum(),
        playTime,
        averageResponseTime: averageResponseTime(),
      },
    ];
  }
}
