




























import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryLogResponse } from "@/generated/adminapi";

@Component
export default class PlayAudio extends Vue {
  @Prop({ required: true, default: {} }) historyLog!: HistoryLogResponse;

  flag = false;
  audioFile = {} as HTMLAudioElement;
  audioList: { index: number; audioFile: string }[] = [];
  loadingState = false;

  async playAudio(): Promise<void> {
    try {
      this.loadingState = true;

      const data = await this.$_apiHelper().v1AdminContentVoices({
        historyLogUUID: this.historyLog.UUID,
        serverUrl: "",
      });

      if (typeof data == "string") {
        this.$_haii.analytics.errorEvent(data);
        return this.$_errorMessage(data);
      }

      if (!data.voices || !data.voices[0]) {
        this.$_haii.analytics.errorEvent(this.$_haiiText("noData"));
        return this.$_errorMessage(this.$_haiiText("noData"));
      }
      this.$_haii.analytics.readEvent(this.$_haiiName() + "_handleFetchData");

      this.flag = true;

      data.voices.forEach((value) => {
        this.audioList.push({
          index: value.nth,
          audioFile: "data:audio/ogg;base64," + value.voice[0],
        });
      });
    } catch (e) {
      this.$_errorMessage(e);
    } finally {
      this.loadingState = false;
    }
  }
}
