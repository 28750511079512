












import { Component, Prop, Vue } from "vue-property-decorator";
import { HistoryResponse } from "@/generated/adminapi";

@Component
export default class ResultReport extends Vue {
  @Prop({ type: Object, default: null, required: true })
  readonly testerInfo!: HistoryResponse;
}
