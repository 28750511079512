





















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import { DataOptions } from "vuetify";
import { FilterType } from "@/types/objectType";

@Component({
  components: { TextField },
})
export default class ExcelDownloadBtn extends Vue {
  @Prop({ required: true }) dataOptions!: DataOptions;
  @Prop({ required: true }) filter!: FilterType;
  dialog = false;
  excelLoading = false;
  userFlag = false;

  async excelDownload(): Promise<void> {
    try {
      this.excelLoading = true;
      const data = await this.$_apiHelper().v1AdminListHistoryExcel({
        filter: { ...this.filter, userFlag: this.userFlag },
        dataOptions: this.dataOptions,
      });

      if (typeof data === "string")
        return this.$_errorMessage(this.$_haiiText("noData"));

      this.$_haii.analytics.readEvent(this.$_haiiName() + "_excelDownload");

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(data);
      link.download = "UserGameAnalysis.xlsx";
      link.click();
    } catch (error) {
      this.$_errorMessage(error);
    } finally {
      this.excelLoading = false;
    }
  }
}
