













































































































import { Component, Vue } from "vue-property-decorator";
import DatePicker from "@/components/defaultLayout/util/DatePicker.vue";
import FileUploader from "@/components/defaultLayout/util/FileUploader.vue";

import { CistResponse, HistoryExcelUploadResponse } from "@/generated/adminapi";
import XLSX from "xlsx";
import { UploadFileInterface } from "@/types/objectType";
import TextField from "@/components/defaultLayout/util/TextField.vue";

interface excelUploadResponseItemInterface extends CistResponse {
  index: number;
}

@Component({
  components: {
    TextField,
    DatePicker,
    FileUploader,
  },
})
export default class ExcelDataUpload extends Vue {
  dialog = false;
  uploadState = false;
  date = null;
  sheetName = "";
  files: UploadFileInterface[] = [];
  totalNum = 0;
  page = 1;
  excelUploadResponseHeader = [
    {
      text: this.$_haiiText("index"),
      align: "start",
      value: "index",
    },
    {
      text: this.$_haiiText("userName"),
      value: "name",
    },
    {
      text: this.$_haiiText("gender"),
      value: "gender",
    },
    {
      text: this.$_haiiText("age"),
      value: "age",
    },
  ];

  forExcelDownloadHeader = [
    {
      text: "대상자No.",
      value: "index",
    },
    {
      text: "이름",
      value: "name",
    },
    {
      text: "성별",
      value: "gender",
    },
    {
      text: "(만)나이",
      value: "age",
    },
    {
      text: "학력",
      value: "edu",
    },
    {
      text: "교육년수",
      value: "eduYear",
    },
    {
      text: "K-MMSE-2(B) Total Score",
      value: "kMMSETotal",
    },
    {
      text: "K-MMSE-2(B) Percentile",
      value: "kMMSEPercent",
    },
    {
      text: "CIST Total Score",
      value: "cistTotal",
    },
    {
      text: "CIST_result(정상:0, 인지저하의심:1)",
      value: "cistResult",
    },
  ];

  excelUploadResponse: HistoryExcelUploadResponse = null;

  excelUploadResponseItem: excelUploadResponseItemInterface[] = [];

  get width(): string {
    if (this.$vuetify.breakpoint.smAndDown) return "100vw";
    else return "70vw";
  }

  validateInput(): boolean {
    if (!this.sheetName) {
      this.$_errorMessage(this.$_haiiText("inputSheet"), 4000);
      return false;
    }

    if (!this.date) {
      this.$_errorMessage(this.$_haiiText("validateDate"), 4000);
      return false;
    }

    if (!this.files[0]) {
      this.$_errorMessage(this.$_haiiText("inputFile"), 4000);
      return false;
    }
    return true;
  }

  async upload(): Promise<void> {
    try {
      if (this.validateInput()) {
        this.uploadState = true;

        this.$_haii.analytics.updateEvent(this.$_haiiName + "_upload");

        const data = await this.$_apiHelper().v1AdminHistoryExcelUpload({
          startDT: this.date[0],
          endDT: this.date[1],
          file: this.files[0].file,
          sheet: this.sheetName,
        });

        this.handleFetchData(data);
      }
    } catch (e) {
      this.$_errorMessage(e, 4000);
    } finally {
      this.uploadState = false;
    }
  }

  handleFetchData(data: HistoryExcelUploadResponse | string): void {
    if (typeof data == "string") {
      return this.$_errorMessage(data);
    }

    if (!data.cist) {
      this.files = [];
      this.dialog = false;
      return this.$_toastMessage("success", this.$_haiiText("registerSuccess"));
    }

    this.excelUploadResponseItem = [];
    this.excelUploadResponse = data;

    data.cist.forEach((value, index) => {
      this.excelUploadResponseItem.push({
        ...value,
        index: index + 1,
      });
    });
    this.totalNum = data.total;
    this.files = [];
  }

  inputFile(files: UploadFileInterface[]): void {
    this.files = files;
  }

  excelDownload(): void {
    this.$_haii.analytics.readEvent(this.$_haiiName() + "_excelDownload");
    const excelData = XLSX.utils.table_to_sheet(
      document.getElementById("dataTable")
    ); // table id를 넣어주면된다
    const newSheet = XLSX.utils.book_new(); // 새 시트 생성
    XLSX.utils.book_append_sheet(newSheet, excelData, "data"); // 시트 명명, 데이터 지정
    XLSX.writeFile(newSheet, "miss_matched_list.xlsx"); // 엑셀파일 만듬
  }
}
