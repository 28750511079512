































import { Component, Prop, Vue } from "vue-property-decorator";
import JsonViewer from "vue-json-viewer";
import { HistoryLogResponse, HistoryResponse } from "@/generated/adminapi";
import ShowImage from "@/components/defaultLayout/report/ShowImage.vue";
import ResultSummary from "@/components/defaultLayout/report/ResultSummary.vue";
import PlayAudio from "@/components/defaultLayout/report/PlayAudio.vue";

@Component({
  components: {
    JsonViewer,
    ShowImage,
    ResultSummary,
    PlayAudio,
  },
})
export default class GameDetailInfo extends Vue {
  @Prop() historyLog!: HistoryLogResponse;
  @Prop() testerInfo!: HistoryResponse;

  summaryCheck(historyLog: HistoryLogResponse): boolean {
    switch (historyLog.game?.itype) {
      case 8000:
        return true;
      case 8200:
        return true;
      case 8100:
        return true;
      case 8300:
        return true;
      case 8400:
        return true;
      case 8800:
        return true;
      case 9000:
        return true;
    }
    return false;
  }

  imageCheck(historyLog: HistoryLogResponse): boolean {
    switch (historyLog.game?.itype) {
      case 8500:
        return true;
      case 8510:
        return true;
      case 8400:
        return true;
      case 8700:
        return true;
    }

    return false;
  }

  audioCheck(historyLog: HistoryLogResponse): boolean {
    switch (historyLog.game?.itype) {
      case 8900:
        return true;
      case 8400:
        return true;
      case 8910:
        return true;
      case 9000:
        return true;
    }

    return false;
  }
}
