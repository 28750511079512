





















































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { ReportTableDataInterFace } from "@/views/alzguard/reports/ReportList.vue";
import DatePicker from "@/components/defaultLayout/util/DatePicker.vue";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import { GenericResponse } from "@/generated/adminapi";

@Component({
  components: {
    DatePicker,
    TextField,
  },
})
export default class DialogEditHistoryUserInfo extends Vue {
  @Prop() propEditData: ReportTableDataInterFace;
  @VModel() dialog!: boolean;

  get editData(): ReportTableDataInterFace {
    return this.propEditData;
  }

  get phoneMaxLength(): number {
    const regExp = /([0-9]{10,11})/g;

    if (regExp.test(this.editData.phone)) {
      return 11;
    }
    return 13;
  }

  get width(): string {
    if (this.$vuetify.breakpoint.xsOnly) return "100vw";
    if (this.$vuetify.breakpoint.smOnly) return "55vw";
    else return "30vw";
  }

  created(): void {
    document.addEventListener("keydown", this.onEnterKeydown);
  }

  onEnterKeydown(ev: DocumentEventMap["keydown"]): void {
    if (ev.code === "Enter") {
      this.onModifyHistory();
    }
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.onEnterKeydown);
  }

  validatePhone(): boolean {
    const regExp =
      /01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$|([0-9]{10,11})/g;

    if (!regExp.test(this.editData.phone)) {
      this.$_errorMessage(this.$_haiiText("phoneFormatError"));
      return false;
    }
    return true;
  }

  async onModifyHistory(): Promise<void> {
    try {
      if (!this.validatePhone()) {
        return;
      }
      const data = await this.$_apiHelper().v1AdminModifyHistory(
        this.editData.historyUUID,
        {
          ...this.editData,
          displayName: this.editData.name,
          phone: this.editData.phone.replaceAll("-", ""),
          birthDT: this.editData.birthDT,
          gender: this.editData.gender,
          couponUUID: this.editData.couponCode,
        }
      );
      this.handleOnModifyHistory(data);
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  handleOnModifyHistory(data: GenericResponse | string): void {
    if (typeof data === "string") {
      this.$_haii.analytics.errorEvent(data);
      return this.$_errorMessage(data);
    }

    this.$_haii.analytics.updateEvent(
      this.$_haiiName() + "_handleOnModifyHistory",
      this.propEditData,
      this.editData
    );

    this.dialog = false;
    this.$_toastMessage("success", this.$_haiiText("editSuccess"));
    setTimeout(() => {
      this.$router.go(0);
    }, 500);
  }
}
